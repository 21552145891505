body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
}

.pricing-page {
  text-align: left; /* Changed from center to left */
  padding: 20px;
}
  
.product-selector {
  padding: 10px;
  margin-bottom: 20px;
  margin-top: 10px;
  font-size: 16px;
  border-radius: 4px;
  border: 1px solid #ccc;
  width: 200px;
}

.product-selector-label {
  font-weight: bold; /* Make the label bold */
  padding-bottom: 10px; /* Add padding to the bottom */
}
  
.pricing-plans {
  display: flex;
  justify-content: flex-start; /* Changed from center to flex-start */
  gap: 20px;
  flex-wrap: wrap;
  padding: 20px;
}
  
.plan {
  background-color: #f8f9fa;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 20px;
  text-align: left; 
  max-width: 300px;
  width: 100%;
  box-shadow: 0 2px 8px rgba(0,0,0,0.1);
}
  
.plan h2 {
  color: #333;
  margin-bottom: 10px;
}
  
.plan h3 {
  color: #007bff;
  margin-bottom: 15px;
}
  
.plan p {
  color: #666;
  margin-bottom: 15px;
}
  
.features {
  list-style-type: none;
  padding: 0;
  margin-bottom: 15px;
}
  
.features li {
  margin-bottom: 5px;
  display: flex; /* Use flexbox for alignment */
  align-items: flex-start; /* Align items at the top of the flex container */
  font-size: 14px;
}
  
.check-mark {
  color: #28a745;
  margin-right: 10px;
  flex-shrink: 0; /* Prevents the check mark from shrinking */
}
  
.promotion {
  color: #ff4500;
  font-style: italic;
  margin-bottom: 15px;
}
  
.cta {
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
}
  
.cta:hover {
  background-color: #0056b3;
}