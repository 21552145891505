.policy-template {
  padding: 40px;
  font-family: 'Georgia', Times, serif; /* Updated font-family */
}

.policy-template h1 {
  font-size: 2em;
  margin-bottom: 20px;
  font-weight: bold; /* Added font-weight */
}

.policy-template h2 {
  font-size: 1.5em;
  margin-top: 20px;
  font-weight: bold; /* Added font-weight */
}

.policy-template h3 {
  font-size: 1.2em;
  margin-top: 15px;
  font-weight: bold; /* Added font-weight */
}

.policy-template p {
  font-size: 1em;
  line-height: 1.5;
  text-align: justify; /* Added text-align */
}

.policy-template ul {
  list-style-type: disc;
  margin-left: 5px !important; /* Added !important to ensure the rule is applied */
}

.policy-template li {
  margin-bottom: 10px;
}

.policy-template strong {
  font-weight: bold;
  color: #000;
}

@media (max-width: 600px) {
  .policy-template {
    padding: 10px;
  }

  .policy-template ul {
    margin-left: 2px !important; /* Added !important to ensure the rule is applied */
  }
}