.home-container {
    text-align: center;
    padding: 20px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
}

.services-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 20px;
    margin-top: 20px;
}

.card {
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 20px;
    text-align: left;
    position: relative;
}

.card::after {
    content: '';
    display: block;
    width: 100%;
    height: 4px;
    background-color: lightblue;
    position: absolute;
    bottom: 0;
    left: 0;
}

.card p {
    font-size: 14px;
    line-height: 1.6;
    color: #555;
}

.card-icon {
    width: 100px;
    height: 75px;
    object-fit: cover;
    border-radius: 4px;
}

.learn-more {
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    padding: 10px 20px;
    cursor: pointer;
    margin-top: 10px;
}

.learn-more:hover {
    background-color: #0056b3;
}

/* Media queries for mobile responsiveness */
@media (max-width: 600px) {
    .home-container {
        padding: 10px;
    }

    .hero-content {
        bottom: 5%;
        right: 5%;
        width: 90%;
        padding: 20px;
    }

    .services-grid {
        grid-template-columns: 1fr;
    }

    .card {
        padding: 10px;
    }

    .learn-more {
        padding: 8px 16px;
    }

    .hero-section {
        height: 500px;
    }

    .hero-cta {
        margin-top: 20px;
        margin-bottom: 0;
        padding: 10px 20px;
        position: relative;
        display: none; /* Hide the CTA button */
    }
}

.mobile-menu {
    z-index: 20;
}

.hero-cta {
    background-color: green;
    color: white;
    border: none;
    border-radius: 4px;
    padding: 10px 20px;
    cursor: pointer;
    margin-top: 10px;
}

.hero-section {
    position: relative;
    width: 100%;
    height: 400px;
    background-image: url(/public/images/goldengatebridge.jpg);
    background-size: cover;
    background-position: center;
    z-index: 1;
}

.hero-headline {
    font-size: 2em;
    font-weight: bold;
    margin-bottom: 10px;
}

.hero-content {
    position: absolute;
    top: 25%;
    right: 5%;
    background-color: rgba(255, 255, 255, 0.8);
    padding: 20px;
    border-radius: 8px;
    text-align: left;
    width: 80%;
    max-width: 600px;
    z-index: 10;
}

.ios-install {
    width: 120px;
    height: auto;
    cursor: pointer;
    border: none;
    background: none;
    padding: 0;
}

.ios-install:hover {
    background-color: #005bb5;
}