body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
}

.logo {
  margin-right: 20px;
  cursor: pointer;
  padding-left: 20px; /* Add padding to create space on the left */
}

.logo img {
  height: 40px;
  border-radius: 50%; /* Apply circular mask */
  overflow: hidden; /* Ensure the image is clipped to the border radius */
}

.header {
  background-color: #f9f9f9;
  color: black;
  padding: 10px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.header-content {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative; /* Add position relative to ensure absolute positioning works */
}

.desktop-nav a, .mobile-nav a {
  color: black;
  margin: 0 15px;
  text-decoration: none;
}

.desktop-nav a:hover, .mobile-nav a:hover {
  text-decoration: underline;
}

.menu-toggle {
  display: none;
  background: none;
  border: none;
  cursor: pointer;
  margin-left: auto; /* This aligns the menu icon to the right */
}

.menu-toggle span {
  display: block;
  width: 25px;
  height: 3px;
  background-color: black; /* Change to black or any desired color */
  margin: 5px 0;
}

.mobile-nav {
  display: none;
  background-color: white; /* Ensure this is the desired color */
  position: absolute;
  top: 100%;
  right: 0; /* Align the menu to the right */
  width: 200px; /* Set a width for the menu */
  padding: 20px;
  text-align: left; /* Changed from center to left */
  box-shadow: -2px 2px 10px rgba(0,0,0,0.1); /* Optional shadow for depth */
}

.mobile-nav a {
  display: block;
  margin: 10px 0;
}

.mobile-menu {
  margin-left: auto; /* Ensure the mobile menu is aligned to the right */
}

/* Media query for mobile devices */
@media (max-width: 768px) {
  .desktop-nav {
    display: none;
  }

  .menu-toggle {
    display: block;
    margin-left: auto; /* Ensure the menu icon is aligned to the right */
  }

  .mobile-nav {
    display: block;
    margin-right: 20px;
  }
}

