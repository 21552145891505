body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
}

.carousel {
    width: 100%;
    overflow: hidden;
    position: relative;
  }
  
  .carousel-inner {
    display: flex;
    transition: transform 0.5s ease-in-out;
  }
  
  .carousel-item {
    width: 100%;
    flex: 0 0 100%;
    display: flex;
    align-items: center;
    min-width: 100%;
    box-sizing: border-box;
  }

  .product-panel {
    width: 80%;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    background-color: #fff;
  }
  
  .product-info {
    width: 100%;
    padding: 20px;
    text-align: left; /* Make content left-aligned */
  }
  
  .product-info h2, .product-info h4 {
    margin: 10px 0;
  }
  
  .product-info ul {
    padding-left: 20px; /* Adjusted padding */
    margin: 0; /* Remove default margin */
  }

  .product-info li {
    margin-left: 20px; /* Add margin to align text with bullet points */
  }

  .product-info p {
    line-height: 1.6; /* Add more line space */
  }

  .features-list {
    list-style-type: disc;
  }

  .features-list li {
    margin-bottom: 10px; /* Add space between list lines */
    font-size: 14px; /* Make font size smaller */
  }

  .benefits-list {
    list-style-type: decimal;
  }

  .benefits-list li {
    margin-bottom: 10px; /* Add space between list lines */
    font-size: 14px; /* Make font size smaller */
  }
  
  .carousel-controls {
    position: absolute;
    top: 50%;
    width: 100%;
    display: flex;
    justify-content: space-between;
    transform: translateY(-50%);
  }
  
  .carousel-controls button {
    font-size: 60px; /* Increase font size for larger brackets */
    width: auto; /* Remove fixed width */
    height: auto; /* Remove fixed height */
    margin: 0 10px;
    border: none;
    background-color: transparent; /* Remove background color */
    color: #333; /* Dark color for brackets */
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
  }
  
  .carousel-controls button:hover {
    color: #000; /* Darker color on hover */
  }