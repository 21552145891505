body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
}

.footer {
    background-color: #f9f9f9;
    padding: 20px;
    text-align: center;
    border-top: 1px solid #ddd;
  }
  
  .footer-links {
    margin-bottom: 10px;
  }
  
  .footer-links a {
    margin: 0 10px;
    color: #007bff;
    text-decoration: none;
    font-family: 'Times New Roman', Times, serif; /* Updated font-family */
  }
  
  .footer-links a:hover {
    text-decoration: underline;
  }
  
  .footer p {
    margin: 0;
    color: #555;
    font-size: 0.9em; /* Smaller font size */
  }
  
  /* Media queries for mobile responsiveness */
  @media (max-width: 600px) {
    .footer {
      padding: 10px;
    }
  
    .footer-links {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  
    .footer-links a {
      margin: 5px 0;
    }
  }