.contact-page {
  padding: 20px;
  /*font-family: 'Arial', sans-serif;*/
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
}

.contact-form {
  display: flex;
  flex-direction: column;
  max-width: 600px;
  margin: 0 auto;
  padding: 10px; /* Added padding */
  text-align: left; /* Left-aligned */
}

.contact-form label {
  margin-bottom: 5px;
  font-weight: bold;
}

.contact-form input,
.contact-form textarea {
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
  font-size: 16px; /* Make font larger */
}

.contact-form button {
  padding: 10px 20px;
  width: fit-content; /* Make button less wide */
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.contact-form button:hover {
  background-color: #0056b3;
}

/* Removed cta-link class */

.success-message {
  color: green;
  font-weight: bold;
  margin-bottom: 20px;
}

.error-message {
  color: red;
  font-weight: bold;
  margin-bottom: 20px;
}

@media (max-width: 600px) {
  .contact-page {
    padding: 10px; /* Less padding for mobile */
  }

  .contact-form {
    padding: 5px; /* Less padding for mobile */
  }
}
